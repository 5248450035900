









































































































































































































































@import '../../../../assets/styles/components/table';
.actions {
  width: 100%;
  .itens {
    height: 40px !important;
  }
}
.v-btn--contained.button-bonus {
    font-size: 14px;
    border-radius: 0 !important;
    color: $colorPrimary !important;
    border: 1px solid $colorPrimary !important;
    box-shadow: none !important;
    font-weight: bold !important;
    height: 35px !important;
    margin-right: 5px !important;
    font-size: 12px !important;
    border-radius: 5px !important;
    min-width: 100px !important;
    max-width: 100px !important;

    &.alto {
      background: $colorSuccess !important;
      color: white !important;
    }

    &.buscar {
      background: $colorPrimary !important;
      color: white !important;
    }
}
.button-dates {
  button {
    min-height: 34px !important
  }
}
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
